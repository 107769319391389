<template>
  <div class="abilityDetails">
    <!-- 标题 -->
    <div class="title">我的健康</div>
    <div class="empty"></div>
    <div class="content">
      <div class="question">请填写主要的健康问题</div>
      <!-- 问卷内容 -->
      <div class="questionnairesurvey">
        <div class="questionnaire" v-for="(item, index) in quesDetailList " :key="item.index">
          <div class="questTitle">{{(index + 1) + '、' + item.titleName }}
            <!-- 单选框 -->
            <van-radio-group v-model="item.value">
              <van-radio name="1" shape="square" checked-color="#FF9580">是</van-radio>
              <van-radio name="2" shape="square" checked-color="#FF9580">否</van-radio>
            </van-radio-group>
            <div v-if="item.value == 1 && item.quesDetail" style="margin: 5px 0 0 30px;">
              <div v-if="item.quesDetail.case1">
                <div>
                  <span>{{ item.quesDetail.case1 }}</span>
                  <!-- <span>{{ item.quesDetail.attach }}</span> -->
                  <input v-model="item.quesDetail.value1" type="text" size="10" name="" class="myttpp">
                </div>
              </div>
              <div v-if="item.quesDetail.case2">
                <div>
                  <span>{{ item.quesDetail.case2 }}</span>
                  <input v-model="item.quesDetail.value2" type="text" size="10" name="" class="myttpp">
                </div>
              </div>
              <div v-if="item.quesDetail.drug">
                <div style="text-align:left"></div>
                <div>
                  <van-radio-group v-model="item.quesDetail.value" style="margin: 0;display: flex;">
                    <span style="margin: 10px 0 0 0;">未服药</span>
                    <van-radio name="5" shape="square" checked-color="#FF9580"
                      style="margin: 10px 0 0 10px;"></van-radio>
                    <span style="margin: 10px 0 0 10px;">已服药</span><van-radio name="6" shape="square"
                      checked-color="#FF9580" style="margin: 10px 0 0 10px;">
                      <div><input v-model="item.quesDetail.value5" type="text" size="5" name=""
                          class="myttpp"><span>年</span></div>
                    </van-radio>
                  </van-radio-group>
                </div>
              </div>
              <div v-if="item.quesDetail.drugName"><span>已注射胰岛素</span><input v-model="item.quesDetail.value3"
                  type="text" size="2" name="" class="myttpp"><span>年</span><input v-model="item.quesDetail.value4"
                  type="text" size="2" name="" class="myttpp"><span>月</span></div>
              <div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="line"></div>
          <van-cell @click="showPopup">预览</van-cell>
          <van-popup v-model="show">
            <div class="questionnaires" v-for="(item, index) in quesDetailList " :key="item.index"
              style="font-size: 18px;">
              <div class="questTitles" style="padding: 10px;">
                <div>{{(index + 1) + '、' + item.titleName }}</div>
                <!-- 单选框 -->
                <van-radio-group v-model="item.value" disabled>
                  <van-radio name="1" shape="square" checked-color="#FF9580">是</van-radio>
                  <van-radio name="2" shape="square" checked-color="#FF9580">否</van-radio>
                </van-radio-group>
                <div v-if="item.value == 1 && item.quesDetail" style="font-size: 12px;">
                  <div v-if="item.quesDetail.case1">
                    <div>
                      <span>{{ item.quesDetail.case1 }}</span>
                      <input v-model="item.quesDetail.value1" type="text" size="10" name="" class="myttpp" disabled>
                    </div>
                  </div>
                  <div v-if="item.quesDetail.case2">
                    <div>
                      <span>{{ item.quesDetail.case2 }}</span>
                      <input v-model="item.quesDetail.value2" type="text" size="10" name="" class="myttpp" disabled>
                    </div>
                  </div>
                  <div v-if="item.quesDetail.drug">
                    <div style="text-align:left"></div>
                    <div>
                      <van-radio-group v-model="item.quesDetail.value" style="margin: 0;display: flex;" disabled>
                        <span style="margin: 10px 0 0 0;">未服药</span>
                        <van-radio name="5" shape="square" checked-color="#FF9580" style="margin: 10px 0 0 10px;"
                          disabled></van-radio>
                        <span style="margin: 10px 0 0 10px;">已服药</span><van-radio name="6" shape="square"
                          checked-color="#FF9580" style="margin: 10px 0 0 10px;">
                          <div><input v-model="item.quesDetail.value5" type="text" size="5" name="" class="myttpp"
                              disabled><span>年</span></div>
                        </van-radio>
                      </van-radio-group>
                    </div>
                  </div>
                  <div v-if="item.quesDetail.drugName"><span>已注射胰岛素</span><input v-model="item.quesDetail.value3"
                      type="text" size="2" name="" class="myttpp" disabled><span>年</span><input
                      v-model="item.quesDetail.value4" type="text" size="2" name="" class="myttpp"
                      disabled><span>月</span></div>
                  <div>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit" @click="submit()">提交</div>
          </van-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetHealQuestionInfo, ReLoadQuestion } from '@/api/index'
import { mapGetters } from 'vuex'
export default {
  name: 'MyHealthSL',
  components: {

  },
  data() {
    return {
      type: 0,
      quesDetailList: [],
      radio1: '',
      radio2: '',
      text: '',
      text1: '',
      radio: '5',
      show: false,
    }
  },
  created () {
    const openid = this.$query('openid')
    const token = this.$query('token')
    const userid = this.$query('userid')
    token && this.$store.commit('SET_TOKEN', token)
    openid && this.$store.commit('SET_OPENID', openid)
    userid && this.$store.commit('SET_USERID', userid)
  },
  mounted() {
    this.getHealQuestionInfoList()
  },

  computed: {
    ...mapGetters([
      'userid'
    ])
  },
  methods: {
    showPopup() {
      this.show = true;
    },
    getHealQuestionInfoList() {
      // this.$toast.loading({
      //   message: '获取信息中'
      // })
      // alert(this.userid,"22323")
      console.log(this.userid,"22222222222222")
      GetHealQuestionInfo({ userId: this.userid }).then(res => {
        
        // GetHealQuestionInfo({ userId: 'Heal_dd560190-ea42-4feb-bc40-70171e687ec4' }).then(res => {
        const { data } = res
        if (data) {
          this.quesDetailList = data
          console.log('this.quesDetailList', this.quesDetailList)
        } else {
          this.$toast.fail('暂无报告数据')
        }
      })
    },
    submit: function () {
      // console.log(this.userid)
      const a = []
      for (let i = 0; i < this.quesDetailList.length; i++) {
        if (this.quesDetailList[i].value == 1) {
          const b = {
            id: this.quesDetailList[i].id
          }
          a.push(b)
          if (this.quesDetailList[i].quesDetail) {
            if (this.quesDetailList[i].quesDetail.value == 6) {
              if (this.quesDetailList[i].quesDetail.drug) {
                a[a.length - 1].drug = this.quesDetailList[i].quesDetail.value5
              } else {
                this.$toast('请补充数据')
              }
            }
            if (this.quesDetailList[i].quesDetail.case1) {
              if (this.quesDetailList[i].quesDetail.value1) {
                a[a.length - 1].case1 = this.quesDetailList[i].quesDetail.value1
              } else {
                this.$toast('请补充数据')
              }
            }
            if (this.quesDetailList[i].quesDetail.case2) {
              if (this.quesDetailList[i].quesDetail.value2) {
                a[a.length - 1].case2 = this.quesDetailList[i].quesDetail.value2
              } else {
                this.$toast('请补充数据')
              }
            }
            if (this.quesDetailList[i].quesDetail.drugName) {
              if (this.quesDetailList[i].quesDetail.value3) {
                a[a.length - 1].drugName = Number(this.quesDetailList[i].quesDetail.value3) + Number(this.quesDetailList[i].quesDetail.value4)
              } else {
                this.$toast('请补充数据')
              }
            }
          }
        }
      }
      console.log('a', a)
      const dataJson = JSON.stringify(a)
      console.log(dataJson, 'dataJson')
      this.ReLoadQuestion(dataJson)

    },
    // 问卷回填接口
    ReLoadQuestion(data) {
      // this.$toast.loading({
      //   message: '获取信息中'
      // })
      ReLoadQuestion({ userId: this.userid, healJson: data }).then(res => {
        if (res.code == 0) {
          this.$router.push('/myhealthSLOne')
          console.log(res, "成功！")
          this.$toast(res.msg, '暂无报告数据')
        } else {
          this.$toast(res.msg)
        }
      })
    }
    // 跳转
    // goNext(){

    // }
  },

}
</script>

<style lang="scss" scoped>
.van-cell {
  width: 165px;
  height: 53px;
  line-height: 40px;
  margin: 0 auto;
  // text-align: center;
}

.van-cell__value--alone {
  text-align: center;
}

.abilityDetails {
  // height: 100%;
  width: 100%;
  background: linear-gradient(rgba(167, 137, 255, 0.5) 100%, rgba(136, 103, 237, 0.6)100%), url("../../../assets/myhealth.png");
  background-size: 100%;
  background-color: #9966FF;
  color: #4f4f4f;
  font-size: 22px;
  position: relative;

  .title {
    text-align: center;
    margin-top: -20px;
  }

  .empty {
    width: 150px;
    height: 8px;
    background: #FF8563;
    border-radius: 10px;
    margin: 150px 10px 0 120px;
  }

  .content {
    width: 100%;
    height: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background: #efefef;
    // margin-top: -10px;
    color: #808080;

    .question {
      height: 40px;
      font-size: 20px;
      position: relative;
      text-align: center;
      padding: 20px;
      color: #4F4F4F
    }

    .question::before {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      left: 75px;
      top: 82%;
      transform: translateY(-50%);
      border-radius: 5px;
      background: #4F4F4F;
    }

    .question::after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      right: 75px;
      top: 82%;
      transform: translateY(-50%);
      border-radius: 5px;
      background: #4F4F4F;
    }

    .questionnairesurvey {
      margin-top: 20px;
      color: #898989;
      font-size: 18px;

      .questionnaire {
        margin-top: 15px;
      }

      .questTitle {
        margin: 5px 0 0 30px;
      }

      /deep/.van-radio-group {
        display: flex;
        margin: -21px 0 0 220px;
      }

      /deep/.van-radio {
        margin-left: 5px;
        font: #898989;
      }

    }

    .line {
      border-bottom: 1px solid #e2e1e7;
      width: 75%;
      margin: 20px 40px;
      text-align: center;
    }

    .submit {
      width: 165px;
      height: 53px;
      line-height: 40px;
      text-align: center;
      font-size: 25px;
      border-radius: 30px;
      background: url('../../../assets/myHealthy/btnBackground.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      margin: 0 auto;
    }
  }
}

.van-cell__value--alone {
  width: 165px;
  height: 53px;
  line-height: 45px;
  // text-align: center;
  font-size: 25px;
  border-radius: 30px;
  background: url('../../../assets/myHealthy/btnBackground.png') no-repeat;
  background-size: 100% 100%;
  color: #fff !important;
  margin: 0 auto;
}
.van-cell{
  background: #f2f2f2;
}
.myttpp {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px dashed #898989;
  // border-style: dashed;
  background: #efefef;
  margin-left: 13px;
}
</style>
